<template>
  <v-container>
    <v-row wrap justify-space-between>
      <v-col class="text-center justify-center pa-0">
        <v-card tile color="transparent" class="pt-4 elevation-0" style="transition: all 0.15s">
          <v-card-title class="font-weight-medium justify-center text-center display-2">
            Download Manager for Speckle
          </v-card-title>
          <v-card-text class="py-5 subtitle-1">
            Manage your Speckle Connectors and Accounts with ease.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row wrap justify-space-between>
      <v-col class="align-center ma-10">
        <div class="d-flex flex-no-wrap text-center justify-center">
          <v-btn class="ml-4 mt-3 primary" @click="downloadOriginalFile2('manager.exe')">
            <v-icon left>mdi-microsoft-windows</v-icon>
            Manager for Windows
          </v-btn>
          <v-btn class="ml-4 mt-3 primary" @click="downloadOriginalFile2('manager.dmg')">
            <v-icon left>mdi-apple</v-icon>
            Manager for mac
          </v-btn>
        </div>
        <div class="d-flex flex-no-wrap text-center justify-center">
          <v-btn class="ml-4 mt-8" @click="downloadOriginalFile('SpeckleManager Setup.exe')">
            <v-icon left>mdi-microsoft-windows</v-icon>
            Manager for Windows (legacy)
          </v-btn>
          <v-btn class="ml-4 mt-8" @click="downloadOriginalFile('SpeckleManager Setup.dmg')">
            <v-icon left>mdi-apple</v-icon>
            Manager for mac (legacy)
          </v-btn>

          <!-- <v-card class="ma-5 elevation-10" width="350" rounded="lg">
                <v-img class="elevation-0" contain src="@/assets/manager.png"></v-img>
              </v-card> -->
        </div>
      </v-col>
    </v-row>

    <v-row wrap justify-space-between class="mt-10">
      <v-col class="align-center pa-0">
        <v-card tile color="transparent" class="pt-4 elevation-0" style="transition: all 0.15s">
          <v-card-title class="font-weight-light">Manual Installation (Windows only)</v-card-title>
          <v-card-text class="py-5 subtitle-1">
            Manual installers of our connectors are also available, please note that in some cases
            Manager is still needed to log into your account.
            <v-alert icon="mdi-alert" class="mt-5 mb-0" type="warning">
              By installing our connectors you agree to their
              <a href="https://speckle.systems/policy/terms" target="_blank">EULA.</a>
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="connectors.length > 0" class="pb-5">
      <v-col v-for="connector in connectors" :key="connector.slug" cols="12" xl="3" md="4" sm="6">
        <connector :connector="connector" />
      </v-col>
    </v-row>
    <v-row v-else align-content="center" align="center" justify="center">
      <v-col align-content="center" align="center" justify="center">
        <v-progress-circular class="ma-10" color="primary" indeterminate />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Connector from './Connector'
import axios from 'axios'
import mixpanel from 'mixpanel-browser'

const spacesEndpoint = 'https://releases.speckle.dev'

export default {
  name: 'Connectors',
  components: {
    Connector
  },
  data: () => ({
    connectors: []
  }),
  async mounted() {
    mixpanel.init('acd87c5a50b56df91a795e999812a3a4', {
      // eslint-disable-next-line camelcase
      api_host: 'https://analytics.speckle.systems'
    })
    mixpanel.track('Visit Releases')

    let tags = await axios.get(
      'https://v1.speckle.systems/ghost/api/v3/content/tags?key=c895981da23dbb5c87ee7192e2&limit=all'
    )

    let connectorsTemp = []

    //let connectors = []
    for (let tag of tags.data.tags) {
      if (!tag.codeinjection_head) continue
      // eslint-disable-next-line camelcase
      tag.codeinjection_head = tag.codeinjection_head.replace(/\s/g, '')
      let connectorTag = tag.codeinjection_head.match(/(window.connectorTag=true)/)

      if (!connectorTag) continue

      // let videoLink = tag.codeinjection_head.match(/(?<=window.videoLink=")(.*?)(?=")/)
      // tag.videoLink = videoLink ? videoLink[0] : null

      // let docsLink = tag.codeinjection_head.match(/(?<=window.docsLink=")(.*?)(?=")/)
      // tag.docsLink = docsLink ? docsLink[0] : null

      // let installLink = tag.codeinjection_head.match(/(?<=window.installLink=")(.*?)(?=")/)
      // tag.installLink = installLink ? installLink[0] : null

      // let sourceLink = tag.codeinjection_head.match(/(?<=window.sourceLink=")(.*?)(?=")/)
      // tag.sourceLink = sourceLink ? sourceLink[0] : null

      // let processNames = tag.codeinjection_head.match(/(?<=window.processNames=")(.*?)(?=")/)
      // tag.processNames = processNames ? processNames[0].split(',') : null

      // let connectorGuid = tag.codeinjection_head.match(/(?<=window.connectorGuid=")(.*?)(?=")/)
      // tag.connectorGuid = connectorGuid ? connectorGuid[0] : null

      // let community = tag.codeinjection_head.match(/(?<=window.community=")(.*?)(?=")/)
      // tag.community = community ? community[0] : null

      let videoLink = tag.codeinjection_head.match(/window.videoLink="([\s\S]*?)"/)
      tag.videoLink = videoLink ? videoLink[1] : null

      let docsLink = tag.codeinjection_head.match(/window.docsLink="([\s\S]*?)"/)
      tag.docsLink = docsLink ? docsLink[1] : null

      let installLink = tag.codeinjection_head.match(/window.installLink="([\s\S]*?)"/)
      tag.installLink = installLink ? installLink[1] : null

      let sourceLink = tag.codeinjection_head.match(/window.sourceLink="([\s\S]*?)"/)
      tag.sourceLink = sourceLink ? sourceLink[1] : null

      let processNames = tag.codeinjection_head.match(/window.processNames="([\s\S]*?)"/)
      tag.processNames = processNames ? processNames[1].split(',') : null

      let connectorGuid = tag.codeinjection_head.match(/window.connectorGuid="([\s\S]*?)"/)
      tag.connectorGuid = connectorGuid ? connectorGuid[1] : null

      let community = tag.codeinjection_head.match(/window.community="([\s\S]*?)"/)
      tag.community = community ? community[1] : null

      try {
        //check if a manager connector
        if (tag.installLink && tag.installLink.includes('SpeckleManager')) {
          tag.directDownload = true
          let response = await axios.get(`${spacesEndpoint}/manager2/feeds/${tag.slug}.json`)
          console.log(response)
          let versions = response.data.Versions.sort(function (a, b) {
            return new Date(b.Date) - new Date(a.Date)
          })

          tag.stable = versions.find((x) => !x.Prerelease)
          console.log(tag.stable)
          tag.versions = versions
        } else tag.directDownload = false
        connectorsTemp.push(tag)
      } catch {
        //nada
      }
    }

    this.connectors = connectorsTemp
  },
  methods: {
    async downloadOriginalFile(filename) {
      let url = `${spacesEndpoint}/manager/${filename}`

      let a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = url
      a.download = filename
      a.click()
      document.body.removeChild(a)

      mixpanel.track('Manager Download', {
        os: filename.includes('exe') ? 'win' : 'mac'
      })
    },
    async downloadOriginalFile2(filename) {
      let url = `${spacesEndpoint}/manager2/installer/${filename}`

      let a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = url
      a.download = filename
      a.click()
      document.body.removeChild(a)

      mixpanel.track('Manager Download', {
        os: filename.includes('exe') ? 'win' : 'mac'
      })
    }
  }
}
</script>
